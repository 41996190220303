import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import { Global } from '@emotion/react'
import { useTranslation, Trans } from 'react-i18next'
import axios from 'axios'

import { useAuth0 } from 'utils/react-auth0-spa'
import GlobalStyles from 'styles/GlobalStyles'
import {
  StripeCheckoutRedirectContainer,
  ShapeContainer,
  StripeCheckoutContentContainer,
  StripeCheckoutRedirectHeader,
  StripeCheckoutImage,
  StripeCheckoutRedirectDescription,
  StripeCheckoutRedirectButton,
} from 'components/StripeCheckout'
import LottieShape from 'components/LottieShape'
import AbstractCongratulationsAnimation from 'components/Animations/AbstractCongratulationsAnimation'
import { trackPaymentSuccessEvent } from 'utils/digitalGoAnalytics'
import success from 'images/png/success.png'

const Success = () => {
  const ACCOUNT_PATH = '/digital-go/account/'
  const SUPPORT_EMAIL = 'support@fluentworlds.com'

  const { getTokenSilently, auth0Loading } = useAuth0()
  const { t, ready } = useTranslation('paymentSuccessPage')

  useEffect(() => {
    const getLatestSubscription = async () => {
      try {
        const accessToken = await getTokenSilently()

        const firebaseResponse = await axios({
          method: 'post',
          url: process.env.GATSBY_FIREBASE_SUBSCRIPTIONS_CLOUD_URL,
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          data: {
            query: `
              query {
                getLatestDigitalGoSubscription {
                  stripeSubscriptionId
                }
              }
            `,
          },
        })

        if (firebaseResponse.data.data.getLatestDigitalGoSubscription) {
          trackPaymentSuccessEvent(
            firebaseResponse.data.data.getLatestDigitalGoSubscription
              .stripeSubscriptionId,
          )
        }
      } catch (error) {
        console.error(error)
      }
    }

    if (!auth0Loading) {
      getLatestSubscription()
    }
  }, [auth0Loading, getTokenSilently])

  return (
    <>
      <Helmet>
        <title>FluentWorlds</title>
      </Helmet>
      <Global styles={GlobalStyles} />
      <StripeCheckoutRedirectContainer>
        <ShapeContainer>
          <LottieShape
            size={500}
            animationData={AbstractCongratulationsAnimation}
          />
        </ShapeContainer>
        {!ready ? null : (
          <StripeCheckoutContentContainer>
            <StripeCheckoutRedirectHeader>
              <StripeCheckoutImage src={success} alt="success" />
              {t('thankYou')}
            </StripeCheckoutRedirectHeader>
            <StripeCheckoutRedirectDescription>
              {t('startExploring')}
            </StripeCheckoutRedirectDescription>
            <StripeCheckoutRedirectDescription>
              <Trans i18nKey="paymentSuccessPage:haveQuestions">
                <a
                  css={`
                    color: rgb(63, 129, 238);
                  `}
                  href="mailto:support@fluentworlds.com"
                  aria-label="EmailSupport"
                >
                  {SUPPORT_EMAIL}
                </a>
              </Trans>
            </StripeCheckoutRedirectDescription>
            <Link to={ACCOUNT_PATH}>
              <StripeCheckoutRedirectButton>
                {t('goToAccount')}
              </StripeCheckoutRedirectButton>
            </Link>
          </StripeCheckoutContentContainer>
        )}
      </StripeCheckoutRedirectContainer>
    </>
  )
}

export default Success
